import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmailIcon from "@mui/icons-material/Email";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import { Divider } from "@mui/material";
import {
  getWebsiteIdFromTxtRecord,
  getWebsiteInfo,
} from "../../slices/websites";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const websiteID = "afdb866b-326f-0fb6-b";
  const { websiteInfo } = useSelector((state) => state.websites);
  const takiChatUrl = process.env.REACT_APP_TAKICHAT_URL;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    // if (websiteID?.length == 0) {
    //   dispatch(getWebsiteIdFromTxtRecord());
    // }
    if (websiteID.length !== 0 && Object.keys(websiteInfo).length === 0) {
      dispatch(getWebsiteInfo(websiteID));
    }
  }, [dispatch, websiteID]);

  return (
    <footer>
      <Divider />
      <div className="footer-main">
        <div className="footer-title">
          <h3>{t("لم تجد ماتبحث عنه؟")}</h3>
        </div>
        <div className="footer-text">
          {t("تحدث معنا أو تواصل عبر البريد الإلكتروني")}
        </div>
        <div className="footer-buttons">
          <div
            className="footer-buttons-chat"
            style={{
              backgroundColor: websiteInfo?.color
                ? websiteInfo?.color
                : "#1972f5",
            }}
          >
            <ModeCommentIcon style={{ padding: "0" }} />
            <span className="footer-buttons-chat-text">{t("تحدث معنا")}</span>
          </div>

          <a href={`mailto:${websiteInfo?.email}`}>
            <div
              className="footer-buttons-mail"
              style={{
                backgroundColor: websiteInfo?.color
                  ? websiteInfo?.color
                  : "#1972f5",
              }}
            >
              <EmailIcon />
              <span className="footer-buttons-mail-text">
                {t("تواصل معنا عبر البريد الإلكتروني")}
              </span>
            </div>
          </a>
        </div>
      </div>
      <Divider />
      <div className="footer-copyright">
        <p>Support {websiteInfo?.name} 2024 ©</p>
        <p>
          We run on{" "}
          <a
            href={takiChatUrl}
            target="_blank"
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Messaggera
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
